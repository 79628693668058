import {KeycloakProfile} from 'keycloak-js'

export interface AuthenticatedUser {
  name: string
  email: string
  id: string
}

export interface Authenticator {
  init: (done: () => void) => void
  isInitialized: () => boolean
  getProfile: () => KeycloakProfile | null
  getToken: () => string | undefined
  getTokenType: () => string | undefined
  getUserAttribute: (attributeName: string) => string | string[] | boolean | null
  hasGroup: (groupName: string) => boolean
  hasRole: (roleName: string[]) => boolean
  isUserName: (username: string[]) => boolean
  isAuthenticated: () => boolean
  hasPermission: (permission: Permission | Permission[]) => boolean
  login: (broker?: string, redirectUrl?: string | null, scope?: string | null) => void
  logout: (redirectUrl?: string | null) => void
  currentUser: () => AuthenticatedUser | undefined
}

export enum Permission {
  ARFBR_FREE = 'arfbr-free',
  ARFBR_PLUS = 'arfbr-plus',
  ARFBR_PREMIUM = 'arfbr-premium',
  KVSYSTEM = 'kvsystem',
  BOOKS = 'books',
  SETTINGS = 'settings',
  CP_ADMIN_ALL = 'cp-admin-all',
  CP_ADMIN_LOHNDATEN = 'cp-admin-lohndaten',
  CP_ADMIN_INDEXING = 'cp-admin-indexing',
  API_LOHNDATEN = 'api-lohndaten',
}
